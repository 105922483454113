import React from 'react'
import Sheetrock from 'sheetrock'
import './styles.scss'
import {
  ArticleCard,
  getArticleTeaserData,
  ArticleData
} from '@ta-interaktiv/react-article-teasers'
import { nest } from 'd3-collection'
import 'ta-semantic-ui/semantic/dist/components/rail.css'
import 'ta-semantic-ui/semantic/dist/components/loader.css'
import FeedbackMessage from '@ta-interaktiv/react-feedback-message'
import { parseHostname } from '@ta-interaktiv/parse-hostname'
import { Waypoint } from 'react-waypoint'

type Props = {}

interface State {
  requestState: 'loading' | 'error' | 'ready'
  downloadedArticles: Map<string, ArticleData>
  allDownloadedArticles: Map<string, ArticleData>
  theLimit: number
  articlesToLoad: number
  allArticles: Map<string, ArticleData>
  isLoadingMoreArticles: false
}

type PortfolioEntry = {
  ID: string
  Title: string
  Description: string
  Icon: string
  online: string
  Aktuell: string
}

type Row = {
  cellsArray: string[]
  labels: string[]
  num: number
  cells: PortfolioEntry
}

const formatMonthName = new Intl.DateTimeFormat('de-CH', { month: 'long' })

const publicationName = parseHostname().publicationName

export class PortfolioTeasers extends React.Component<Props, State> {
  state = {
    requestState: 'loading',
    downloadedArticles: new Map<string, ArticleData>(),
    allDownloadedArticles: new Map<string, ArticleData>()
  }

  // Create a map of the results
  allArticles: Map<string, PortfolioEntry> = new Map()
  portfolioArticles: Map<string, PortfolioEntry> = new Map()

  componentDidMount() {
    this.getGoogleData()
    this.setState({
      theLimit: 15,
      articlesToLoad: 15
    })
  }

  getGoogleData() {
    this.setState({
      requestState: 'loading'
    })

    Sheetrock({
      url:
        'https://docs.google.com/spreadsheets/d/1F3RQGyZL50ZoSw1QfpS2zctQuetDcpSnX85ioOcY0bI/edit#gid=0',
      query: 'select A, B, C, D, E, F WHERE E = "yes"',
      labels: ['ID', 'Title', 'Description', 'Icon', 'online', 'Aktuell'],
      callback: (error, options, response) => {
        if (error) {
          // Get out if something went wrong
          console.group('Sheetrock Download')
          console.log(error)
          console.groupEnd()
          return
        }

        if (process.env.NODE_ENV === 'development') console.log(response)

        this.setState({
          allArticles: response
        })
        // Fill allArticles map, so we have a reference point when
        // filling out the data later on.
        response.rows.map((row: Row, index) => {
          if (row.num === 0) {
            return
          }
          this.allArticles.set(row.cells.ID, row.cells)
        })

        // Download all article data from the server
        this.allArticles.forEach((val, id) => {
          getArticleTeaserData(publicationName, id)
            .then(data => {
              if (!data) {
                console.debug(
                  `Article with ID ${id} not found or published on ${publicationName}`
                )
                return
              }
              // Save the article data into the component state. Since we
              // should have at least one article to display, it is save to
              // set the request state to 'ready'.
              this.setState(previousState => {
                let allDownloadedArticles = previousState.allDownloadedArticles
                allDownloadedArticles.set(id, data)
                const toSort = Array.from(allDownloadedArticles)
                toSort.sort(function(a, b) {
                  return b[1].publishingDate - a[1].publishingDate
                })
                allDownloadedArticles = new Map(toSort)
                return {
                  allDownloadedArticles: allDownloadedArticles,
                  requestState: 'ready'
                }
              })
            })
            .catch(error => {
              console.debug(error)
            })
        })

        // Fill portfolioArticles map, so we have a reference point when
        // filling out the data later on.
        response.rows.map((row: Row, index) => {
          if (row.num === 0 || index > this.state.theLimit) {
            return
          }

          this.portfolioArticles.set(row.cells.ID, row.cells)
        })
        // Download all article data from the server
        this.portfolioArticles.forEach((val, id) => {
          getArticleTeaserData(publicationName, id)
            .then(data => {
              if (!data) {
                console.debug(
                  `Article with ID ${id} not found or published on ${publicationName}`
                )
                return
              }
              // Save the article data into the component state. Since we
              // should have at least one article to display, it is save to
              // set the request state to 'ready'.
              this.setState(previousState => {
                let downloadedArticles = previousState.downloadedArticles
                downloadedArticles.set(id, data)
                const toSort = Array.from(downloadedArticles)
                toSort.sort(function(a, b) {
                  return b[1].publishingDate - a[1].publishingDate
                })
                downloadedArticles = new Map(toSort)
                return {
                  downloadedArticles: downloadedArticles,
                  requestState: 'ready'
                }
              })
            })
            .catch(error => {
              console.debug(error)
            })
        })
      }
    })
  }

  loadMoreArticles() {
    this.setState({ isLoadingMoreArticles: true })
    // Fill portfolioArticles map, so we have a reference point when
    // filling out the data later on.
    const toMap = Array.from(this.state.allDownloadedArticles)
    toMap.map((article, index) => {
      if (index > this.state.theLimit) {
        return
      }
      this.portfolioArticles.set(article[0], article[1])
    })
    if (toMap.length <= this.state.theLimit) {
      this.setState({ isLoadingMoreArticles: false })
    }

    // Download NEW article data from the server
    this.portfolioArticles.forEach((val, id) => {
      if (!this.state.downloadedArticles.has(id)) {
        // console.log('id: ', id)
        // console.log('downloadedArticles: ', this.state.downloadedArticles)
        getArticleTeaserData(publicationName, id)
          .then(data => {
            if (!data) {
              console.debug(
                `Article with ID ${id} not found or published on ${publicationName}`
              )
              return
            }
            // Save the article data into the component state. Since we
            // should have at least one article to display, it is save to
            // set the request state to 'ready'.
            this.setState(previousState => {
              let downloadedArticles = previousState.downloadedArticles
              downloadedArticles.set(id, data)
              // console.log('downloadedArticles:', downloadedArticles)
              return {
                downloadedArticles: downloadedArticles,
                requestState: 'ready'
              }
            })
          })
          .catch(error => {
            console.debug(error)
          })
      }
    })
  }

  render() {
    // Display loader if we are still loading
    if (this.state.requestState === 'loading') {
      return (
        <section className='portfolio-teasers ui container'>
          <div className='ui vertical segment' style={{ minHeight: '66vh' }}>
            <div className='ui active huge loader' />
          </div>
        </section>
      )
    }

    // Set up the different nests
    // region Aktuell
    const showcaseArticleIDs: Array<PortfolioEntry> = Array.from(
      this.allArticles.values()
    ).filter(cells => cells.Aktuell !== '')
    // endregion

    // region Date Nesting
    const articlesSortedByPublishingDate = Array.from(
      this.state.downloadedArticles.values()
    ).sort((a, b) => {
      return +a.publishingDate - +b.publishingDate
    })
    const articlesByYearAndMonth = nest()
      .key((d: ArticleData) => new Date(d.publishingDate).getFullYear())
      .key((d: ArticleData) => new Date(d.publishingDate).getMonth())
      .map(articlesSortedByPublishingDate)

    // Get the keys (a.k.a. years) and order them descending
    const sortedYears = articlesByYearAndMonth.keys().sort((a, b) => +b - +a)

    // endregion

    return (
      <section className='portfolio-teasers ui container'>
        <div className='spotlight ui vertical relaxed segment'>
          <div className='relative-positioned'>
            <h2 className='widescreen hidden'>Highlights</h2>
            <div className='ui left close rail large screen or lower hidden'>
              <h2 className=''>Highlights</h2>
            </div>
            <div className='ui vertical segment'>
              <div className='ui two stackable cards'>
                {showcaseArticleIDs.map(portfolioInfo => {
                  const articleData = this.state.allDownloadedArticles.get(
                    portfolioInfo.ID
                  )

                  // If we don't have any article data yet, we abort early
                  if (!articleData) {
                    return
                  }

                  return this.renderArticleCard(
                    articleData,
                    portfolioInfo,
                    portfolioInfo.ID.toString()
                  )
                })}
              </div>
            </div>
            <div className='ui text container infographic'>
              <FeedbackMessage inverted />
            </div>
          </div>
        </div>
        {sortedYears.map(year => {
          const yearlyArticles = articlesByYearAndMonth.get(year)
          const monthsInThisYear = yearlyArticles.keys().sort((a, b) => +b - +a)
          return (
            <div
              className={`ui vertical segment year relative-positioned year-${year}`}
              key={year}
            >
              <h2 className='widescreen hidden'>{year}</h2>
              <div className='ui left close rail large screen or lower hidden'>
                <h2 className=''>{year}</h2>
              </div>
              {monthsInThisYear.map(monthIndex => {
                const monthlyArticles = yearlyArticles.get(monthIndex)
                if (monthlyArticles) {
                  monthlyArticles.sort((a, b) => {
                    return +b.publishingDate - +a.publishingDate
                  })

                  const month = formatMonthName.format(
                    new Date(year, monthIndex)
                  )

                  return (
                    <div
                      className={`month relative-positioned ui very fitted vertical segment ${month}`}
                      key={`${year}-${month}`}
                    >
                      <h4 className='widescreen hidden'>{year}</h4>
                      <h3 className='widescreen hidden'>{month}</h3>
                      <div className='ui left close rail large screen or lower hidden'>
                        <h4 className=''>{year}</h4>
                        <h3 className=''>{month}</h3>
                      </div>
                      <div className='ui three doubling stackable cards'>
                        {monthlyArticles.map((articleData: ArticleData) => {
                          const portfolioInfo = this.portfolioArticles.get(
                            articleData.id.toString()
                          )

                          if (!portfolioInfo) {
                            return
                          }

                          return this.renderArticleCard(
                            articleData,
                            portfolioInfo,
                            articleData.id.toString()
                          )
                        })}
                      </div>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>
          )
        })}
        <Waypoint
          onEnter={event => {
            //console.log('load more articles')
            let newLimit = this.state.theLimit + this.state.articlesToLoad
            //console.log('newLimit:', newLimit)
            this.setState({ theLimit: newLimit }, function() {
              this.loadMoreArticles()
            })
          }}
        />
        {this.state.isLoadingMoreArticles && (
          <div className='ui active centered inline text loader'>
            Weitere Artikel werden geladen...
          </div>
        )}
      </section>
    )
  }

  /**
   * Prepares data and merges article data from the Newsnet API and the
   * portfolio master list from Google Sheets.
   */
  renderArticleCard(
    articleData: ArticleData,
    portfolioInfo: PortfolioEntry,
    id: string
  ) {
    // Prepare properties for the Article Cards
    const properties = {
      ...articleData,
      openInNewWindow: false,
      commentary: portfolioInfo.Description,
      commentaryIcon: portfolioInfo.Icon !== '' ? portfolioInfo.Icon : undefined
    }

    const urlPass = `//${publicationName}.ch/${id}`

    return (
      <ArticleCard
        key={portfolioInfo.ID}
        {...{ ...properties, url: urlPass }}
      />
    )
  }
}
