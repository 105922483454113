import React from 'react'
import { Masthead } from '@ta-interaktiv/react-masthead'
import { PortfolioTeasers } from '../portfolioTeasers'
import 'ta-semantic-ui/semantic/dist/components/reset.css'
import 'ta-semantic-ui/semantic/dist/components/site.css'
import 'ta-semantic-ui/semantic/dist/components/segment.css'
import 'ta-semantic-ui/semantic/dist/components/grid.css'
import 'ta-semantic-ui/semantic/dist/components/table.css'
import 'ta-semantic-ui/semantic/dist/components/header.css'
import 'ta-semantic-ui/semantic/dist/components/label.css'
import 'ta-semantic-ui/semantic/dist/components/divider.css'
import 'ta-semantic-ui/semantic/dist/components/image.css'
import 'styles/App.css'
import styles from './styles.module.scss'
import { ThemeSwitch } from '@ta-interaktiv/react-theme-switch'
import { Footer } from '@ta-interaktiv/react-footer'

const ADDITIONAL_SHARES = 69
const ARTICLE_ID = '12318171'

export default function AppComponent() {
  return (
    <div className='index'>
      <Masthead
        articleId={ARTICLE_ID}
        additionalShares={ADDITIONAL_SHARES}
      />
       <ThemeSwitch />

      <header className='ui vertical very fitted segment'>
          <div className='ui container'>
            <div className='ui header'>
              <h1>
              Das Ressort Daten & Interaktiv der Redaktion Tamedia macht visuellen Journalismus. Hier finden Sie unsere neuesten Datenvisualisierungen, Infografiken und Erzählformate. Für Anregungen oder Fragen
schreiben Sie an <a href='mailto:interaktiv@tamedia.ch'>interaktiv@tamedia.ch</a>.
              </h1>
            </div>
          </div>
        </header>

      <main>
        <div className='ui vertical fitted segment'>
          <PortfolioTeasers />
        </div>
      </main>

      <Footer
        additionalShares={ADDITIONAL_SHARES}
        invertedBottom
        articleId={ARTICLE_ID}
        hidePortfolioTeaser
        hideUserFeedback
        topHalfClassName='basic'
        credits={[
          [
            'Daten & Interaktiv',
            `
                        <span class='${styles.nobreak}'>
                          <a href='https://twitter.com/MarcBrup'>
                            <i class='twitter icon'></i>Marc Brupbacher
                          </a>
                          (Leitung)
                        </span>
                        <span class='${styles.nobreak}'>
                          <a href='https://twitter.com/dominikbalmer'>
                            <i class='twitter icon'></i>Dominik Balmer
                          </a>
                          (Leitung)
                        </span>
                        <br />
                        <span class='${styles.nobreak}'><a href='https://twitter.com/__seebaa'>
                            <i class='twitter icon' ></i>Sebastian Broschinski</a>,</span>
                        <span class='${styles.nobreak}'><a href='https://twitter.com/sven_corn'>
                            <i class='twitter icon' ></i>Svenson Cornehls</a>,</span>
                        <span class='${styles.nobreak}'><a href='https://twitter.com/mathiaslutz'>
                            <i class='twitter icon' ></i>Mathias Lutz</a>,</span>
                        <span class='${styles.nobreak}'><a href='https://twitter.com/pameier'>
                            <i class='twitter icon' ></i>Patrick Meier</a>,</span>
                        <span class='${styles.nobreak}'><a href='https://twitter.com/PVoegeli'>
                            <i class='twitter icon' ></i>Patrick Vögeli</a></span>, <span class='${styles.nobreak}'><a href='https://twitter.com/yannickw3'>
                            <i class='twitter icon' ></i>Yannick Wiget</a></span>, <span class='${styles.nobreak}'>
          `
          ]
        ]}
      />
    </div>
  )
}

AppComponent.defaultProps = {}
